
import { Diagram } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'

@Component({
  name: 'OverviewRankDiagramPreview'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)

  @Ref() readonly thumbnailImageRef!: HTMLImageElement

  @Prop() readonly diagram!: Diagram

  imageError: boolean = false
  thumbnailUrl: string = ''

  created () {
    this.loadThumbnail()
  }

  async loadThumbnail () {
    if (!this.diagramModule.diagramThumbnails[this.diagram.id]) {
      const thumbnail = await this.diagramModule.diagramThumbnailGet({
        diagramId: this.diagram.id,
        landscapeId: this.diagram?.landscapeId,
        versionId: this.diagram?.versionId
      })
      this.thumbnailUrl = thumbnail?.url ?? ''
    } else {
      this.thumbnailUrl = this.diagramModule.diagramThumbnails[this.diagram.id]?.url ?? ''
    }
  }
}
