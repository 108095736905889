
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export type HealthBoxType = 'error' | 'warning' | 'info'

@Component({
  name: 'OverviewHealthCheckBox'
})
export default class extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly subtitle!: string
  @Prop() readonly icon!: string
  @Prop({ default: 'info', required: false }) readonly type!: HealthBoxType

  get colorClasses () {
    switch (this.type) {
      case 'error':
        return 'tw-bg-ruby-500 tw-bg-opacity-10 hover:tw-bg-ruby-400 hover:tw-bg-opacity-10 tw-border-ruby-200 tw-border-opacity-40'
      case 'warning':
        return 'tw-bg-amber-300 tw-bg-opacity-10 hover:tw-bg-amber-200 hover:tw-bg-opacity-10 tw-border-amber-100 tw-border-opacity-40'
      case 'info':
        return 'tw-bg-transparent hover:tw-bg-rock-400 hover:tw-bg-opacity-10 tw-border-white tw-border-opacity-10'
    }
  }

  get iconColor () {
    switch (this.type) {
      case 'error':
        return 'tw-text-ruby-500'
      case 'warning':
        return 'tw-text-amber-300'
      case 'info':
        return 'tw-text-rock-300'
    }
  }

  get textColor () {
    switch (this.type) {
      case 'error':
        return 'tw-text-ruby-200'
      case 'warning':
        return 'tw-text-amber-100'
      case 'info':
        return 'tw-text-rock-300'
    }
  }
}
