
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import getColor from '@/helpers/get-color'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

import { RankItem } from '../../types'

@Component({
  name: 'OverviewRankListItem'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly rankListItem!: any
  @Prop() readonly diagramItem!: RankItem
  @Prop() readonly selected!: boolean
  @Prop() readonly rank!: number

  mouseX: number = 50
  mouseY: number = 50

  centerTimer?: number

  get selectedTab () {
    return this.$queryValue('rank_tab') || this.rankTabForPermission
  }

  get rankBorderColor (): string {
    switch (this.rank) {
      case 0:
        return 'tw-border-amber-200'
      case 1:
        return 'tw-border-rock-200'
      case 2:
        return 'tw-border-beaver-400'
      default:
        return 'tw-border-white'
    }
  }

  get rankTextColor (): string {
    switch (this.rank) {
      case 0:
        return 'tw-text-amber-200'
      case 1:
        return 'tw-text-rock-200'
      case 2:
        return 'tw-text-beaver-400'
      default:
        return 'tw-text-white'
    }
  }

  get backgroundStyle () {
    switch (this.rank) {
      case 0:
        return {
          background: `radial-gradient(circle at ${this.mouseX}% ${this.mouseY}%, rgba(252, 237, 201, 0.20) 0%, rgba(245, 184, 65, 0.00) 100%), #1F2121`
        }
      case 1:
        return {
          background: `radial-gradient(circle at ${this.mouseX}% ${this.mouseY}%, rgba(192, 192, 192, 0.20) 0%, rgba(169, 169, 169, 0.00) 100%), #1F2121`
        }
      case 2:
        return {
          background: `radial-gradient(circle at ${this.mouseX}% ${this.mouseY}%, rgba(205, 127, 50, 0.20) 0%, rgba(160, 82, 45, 0.00) 100%), #1F2121`
        }
      default:
        return {}
    }
  }

  get numberOfAdditionalUsers () {
    return Object.values(this.diagramItem.details.users).length - 3
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion?.landscapeId)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get rankTabForPermission () {
    return this.currentLandscapePermission === 'read' ? 'views' : 'edits'
  }

  get displayedUsers () {
    return Object.values(this.diagramItem.details.users)
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .filter(o => o.name)
      .slice(0, 3)
  }

  userColor (id?: string) {
    return id ? TAG_COLOR_ACTIVE[getColor(id)] : undefined
  }

  userInitials (name?: string) {
    return name ? getInitials(name) : undefined
  }

  updateMousePosition (event: MouseEvent) {
    if (this.centerTimer) {
      clearTimeout(this.centerTimer)
      this.centerTimer = undefined
    }
    const rect = this.rankListItem.getBoundingClientRect()
    this.mouseX = ((event.clientX - rect.left) / rect.width) * 100
    this.mouseY = ((event.clientY - rect.top) / rect.height) * 100
  }

  resetMousePosition () {
    const step = 1
    const interval = 2.5

    const adjustTowardsCenter = () => {
      if (this.mouseX < 50) {
        this.mouseX = Math.min(this.mouseX + step, 50)
      } else if (this.mouseX > 50) {
        this.mouseX = Math.max(this.mouseX - step, 50)
      }

      if (this.mouseY < 50) {
        this.mouseY = Math.min(this.mouseY + step, 50)
      } else if (this.mouseY > 50) {
        this.mouseY = Math.max(this.mouseY - step, 50)
      }

      if (this.mouseX !== 50 || this.mouseY !== 50) {
        clearTimeout(this.centerTimer)
        this.centerTimer = window.setTimeout(adjustTowardsCenter, interval)
      }
    }

    adjustTowardsCenter()
  }

  destroyed () {
    clearTimeout(this.centerTimer)
    this.centerTimer = undefined
  }
}
