
import { Diagram, StatsDetails } from '@icepanel/platform-api-client'
import { format, startOfMonth, startOfToday, startOfWeek, startOfYesterday } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { isDiagramEmpty } from '@/modules/diagram/helpers/empty-diagram'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { OverviewModule } from '../../store'
import { RankItem } from '../../types'
import ButtonTabs, { ButtonTab } from '../button-tabs.vue'
import RankDiagramPreview from './rank-diagram-preview.vue'
import RankEmptyState from './rank-empty-state.vue'
import RankList from './rank-list.vue'

@Component({
  components: {
    ButtonTabs,
    RankDiagramPreview,
    RankEmptyState,
    RankList
  },
  name: 'OverviewTopCharts'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  overviewModule = getModule(OverviewModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  selectedDiagramId: string = ''

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion?.landscapeId)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get timelineTabs (): ButtonTab[] {
    return this.overviewModule.timelineTabs
  }

  get selectedTimeline () {
    return this.$queryValue('timeline') ?? localStorage.getItem('overviewSelectedTimelineTab') ?? 'week'
  }

  get selectedMost () {
    return this.$queryValue('most') ?? localStorage.getItem('overviewSelectedMostTab') ?? this.rankTabForPermission
  }

  get loading () {
    return (
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.modelModule.objectsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get mostTabs (): ButtonTab[] {
    return [
      { id: 'views', name: 'Viewed' },
      { id: 'edits', name: 'Edited' }
    ]
  }

  get selectedDiagram (): Diagram | undefined {
    if (!this.selectedDiagramId.length) {
      return this.diagramItems[0]?.diagram
    }

    return this.diagramItems.find(item => item.diagram.id === this.selectedDiagramId)?.diagram
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get diagrams () {
    return Object
      .values(this.diagramModule.diagrams)
      .filter(o => {
        const model = this.modelModule.objects[o.modelId]
        if (!model || (this.currentDomain && model.domainId !== this.currentDomain.id)) {
          return false
        } else if (o.status !== 'current') {
          return false
        } else if (isDiagramEmpty(o)) {
          return false
        } else {
          return true
        }
      })
  }

  get diagramItems (): RankItem[] {
    return (Object.values(this.diagrams)
      .sort((a, b) => {
        return (this.count(b)?.count ?? 0) - (this.count(a)?.count ?? 0)
      })
      .slice(0, 3)
      .map((diagram) => ({
        details: this.count(diagram) ?? { count: 0, users: {} },
        diagram
      }))
      .filter((item) => item.details.count > 0)
    )
  }

  get rankTabForPermission () {
    return this.currentLandscapePermission === 'read' ? 'views' : 'edits'
  }

  selectDiagram (id: string) {
    this.selectedDiagramId = id
  }

  selectTimeline (timeline: string) {
    this.$replaceQuery({
      timeline
    })

    localStorage.setItem('overviewSelectedTimelineTab', timeline)
  }

  selectMost (most: string) {
    this.$replaceQuery({
      most
    })

    localStorage.setItem('overviewSelectedMostTab', most)
  }

  count (diagram: Diagram): StatsDetails | undefined {
    const now = new Date()
    const nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())

    const startToday = startOfToday()
    const todayStartUtc = Date.UTC(startToday.getUTCFullYear(), startToday.getUTCMonth(), startToday.getUTCDate(), startToday.getUTCHours(), startToday.getUTCMinutes(), startToday.getUTCSeconds())
    const todayStartAtKey = format(todayStartUtc, 'yyyy-MM-dd', { weekStartsOn: 1 })

    const startYesterday = startOfYesterday()
    const yesterdayStartUtc = Date.UTC(startYesterday.getUTCFullYear(), startYesterday.getUTCMonth(), startYesterday.getUTCDate(), startYesterday.getUTCHours(), startYesterday.getUTCMinutes(), startYesterday.getUTCSeconds())
    const yesterdayStartAtKey = format(yesterdayStartUtc, 'yyyy-MM-dd', { weekStartsOn: 1 })

    const weekStartAt = startOfWeek(nowUtc, { weekStartsOn: 1 })
    const weekStartAtKey = format(weekStartAt, 'yyyy-MM-dd', { weekStartsOn: 1 })

    const monthStartAt = startOfMonth(nowUtc)
    const monthStartAtKey = format(monthStartAt, 'yyyy-MM-dd', { weekStartsOn: 1 })

    const periods = this.selectedMost === 'views' ? diagram.stats.views : diagram.stats.edits
    switch (this.selectedTimeline) {
      case 'today':
        return periods.day[todayStartAtKey]
      case 'yesterday':
        return periods.day[yesterdayStartAtKey]
      case 'week':
        return periods.week[weekStartAtKey]
      case 'month':
        return periods.month[monthStartAtKey]
      default:
        return periods.all
    }
  }

  showDiagram (diagram: Diagram) {
    this.$router.push({
      name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
      params: {
        landscapeId: this.currentLandscapeId || ' ',
        versionId: this.currentVersionId
      },
      query: {
        diagram: diagram.id,
        model: diagram.modelId
      }
    })
  }
}
