
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { CarouselType } from '@/modules/overview/types'

@Component({
  name: 'OverviewCarouselHeader'
})
export default class OverviewCarouselHeader extends Vue {
  domainModule = getModule(DomainModule, this.$store)

  @Prop() readonly type!: CarouselType

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get title (): string {
    switch (this.type) {
      case 'pinned':
        return 'Pinned'
      case 'recent':
        return 'Your recent'
    }
  }

  get icon (): string {
    switch (this.type) {
      case 'pinned':
        return '$fas-map-pin'
      case 'recent':
        return '$custom-duotone-clock-rotate-left'
    }
  }

  get showDomainName (): boolean {
    return this.type === 'pinned'
  }
}
