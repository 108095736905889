
import { ActionLog, Flow } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import DiagramDeleteDialog from '@/modules/diagram/components/delete-dialog.vue'
import { isDiagramEmpty } from '@/modules/diagram/helpers/empty-diagram'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import FlowDeleteDialog from '@/modules/flow/components/delete-dialog.vue'
import { FlowModule } from '@/modules/flow/store'
import { HistoryModule } from '@/modules/history/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { SocketModule } from '@/modules/socket/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import { CarouselRecentsTab, IOverviewCarouselItem } from '../../types'
import ButtonTabs from '../button-tabs.vue'
import Carousel from './carousel/index.vue'

@Component({
  components: {
    ButtonTabs,
    Carousel,
    DiagramDeleteDialog,
    FlowDeleteDialog
  },
  name: 'OverviewGrid'
})

export default class OverviewGrid extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  historyModule = getModule(HistoryModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  recentActionLog: ActionLog[] = []
  viewedActionLog: ActionLog[] = []
  fetchedDiagramThumbnails = new Set<string>()
  fetchedFlowThumbnails = new Set<string>()

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get rankTabForPermission (): CarouselRecentsTab {
    return this.landscapePermission === 'read' ? 'views' : 'edits'
  }

  get selectedRecent (): CarouselRecentsTab {
    return this.$queryValue('recent') as CarouselRecentsTab | null ?? localStorage.getItem('overviewSelectedRecentTab') as CarouselRecentsTab | null ?? this.rankTabForPermission
  }

  get recentTab (): { id: CarouselRecentsTab, name: string }[] {
    return [
      { id: 'views', name: 'Viewed' },
      { id: 'edits', name: 'Edited' }
    ]
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get userId () {
    return this.userModule.user?.id
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get modelObjectRoot () {
    return Object.values(this.modelModule.objects).find(o => o.type === 'root' && (!this.currentDomain || o.domainId === this.currentDomain.id))
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get actionLogsListStatus () {
    return this.historyModule.actionLogsListStatus
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get recentlyViewedOrEditedItems () {
    return [...this.recentDiagrams, ...this.recentFlows].sort((a, b) => {
      const dateA = a.viewedOrEditedAt ? new Date(a.viewedOrEditedAt).getTime() : 0
      const dateB = b.viewedOrEditedAt ? new Date(b.viewedOrEditedAt).getTime() : 0
      return dateB - dateA
    })
  }

  get pinnedItems () {
    return [...this.pinnedDiagrams, ...this.pinnedFlows].sort((a, b) => {
      const dateA = a.pinnedAt ? new Date(a.pinnedAt).getTime() : 0
      const dateB = b.pinnedAt ? new Date(b.pinnedAt).getTime() : 0
      return dateA - dateB
    })
  }

  get diagrams () {
    return Object
      .values(this.diagramModule.diagrams)
      .filter(o => {
        const model = o.modelId ? this.modelModule.objects[o.modelId] : undefined
        if (!model || (this.currentDomain && model.domainId !== this.currentDomain.id)) {
          return false
        } else if (o.status !== 'current') {
          return false
        } else if (isDiagramEmpty(o)) {
          return false
        } else {
          return true
        }
      })
  }

  get actionLogs () {
    return this.selectedRecent === 'views' ? this.viewedActionLog : this.recentActionLog
  }

  get pinnedDiagrams () {
    return this.diagrams
      .filter(o => o.pinned)
      .map((diagram): IOverviewCarouselItem => {
        const diagramModelObject = diagram.modelId ? this.modelModule.objects[diagram.modelId] : undefined
        return {
          edits: diagram.stats.edits.all,
          id: diagram.id,
          index: diagram.index,
          lastUpdated: diagram.updatedAt || diagram.createdAt,
          name: diagram.name,
          permission: this.landscapePermission || 'read',
          pinned: 'pinned' in diagram ? diagram.pinned : undefined,
          pinnedAt: 'pinnedAt' in diagram ? diagram.pinnedAt : undefined,
          subtitle: this.currentDomainHandleId ? undefined : diagramModelObject ? this.domainModule.domains[diagramModelObject.domainId]?.name : undefined,
          thumbnailUrl: this.diagramModule.diagramThumbnails[diagram.id]?.url,
          to: {
            name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
            params: {
              landscapeId: this.currentLandscapeId || '',
              versionId: this.currentVersionId
            },
            query: {
              diagram: diagram.id,
              model: diagram.modelId
            }
          },
          type: 'type' in diagram ? diagram.type : 'group',
          views: diagram.stats.views.all
        }
      })
  }

  get pinnedFlows () {
    return Object
      .values(this.flowModule.flows)
      .filter(o => o.pinned && this.diagrams.some(diagram => diagram.id === o.diagramId))
      .map((flow): IOverviewCarouselItem => {
        const diagram = flow.diagramId ? this.diagramModule.diagrams[flow.diagramId] : undefined
        const diagramModel = diagram && diagram.modelId ? this.modelModule.objects[diagram.modelId] : undefined
        return {
          edits: flow.stats.edits.all,
          id: flow.id,
          index: flow.index,
          lastUpdated: flow.updatedAt || flow.createdAt,
          name: flow.name,
          permission: this.landscapePermission || 'read',
          pinned: 'pinned' in flow ? flow.pinned : undefined,
          pinnedAt: 'pinnedAt' in flow ? flow.pinnedAt : undefined,
          thumbnailUrl: this.flowModule.flowThumbnails[flow.id]?.url,
          to: {
            name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
            params: {
              landscapeId: this.currentLandscapeId || '',
              versionId: this.currentVersionId
            },
            query: {
              diagram: diagram ? diagram.handleId : undefined,
              flow: flow.handleId,
              model: diagramModel ? diagramModel.handleId : undefined
            }
          },
          type: 'flow',
          views: flow.stats.views.all
        }
      })
  }

  get recentDiagrams () {
    return this.diagrams
      .filter(o => this.actionLogs.some(log => log.action.id === o.handleId))
      .map((diagram): IOverviewCarouselItem => {
        const diagramModelObject = diagram.modelId ? this.modelModule.objects[diagram.modelId] : undefined
        return {
          edits: diagram.stats.edits.all,
          id: diagram.id,
          index: diagram.index,
          name: diagram.name,
          permission: this.landscapePermission || 'read',
          pinned: 'pinned' in diagram ? diagram.pinned : undefined,
          pinnedAt: 'pinnedAt' in diagram ? diagram.pinnedAt : undefined,
          subtitle: this.currentDomainHandleId ? undefined : diagramModelObject ? this.domainModule.domains[diagramModelObject.domainId]?.name : undefined,
          thumbnailUrl: this.diagramModule.diagramThumbnails[diagram.id]?.url,
          to: {
            name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
            params: {
              landscapeId: this.currentLandscapeId || '',
              versionId: this.currentVersionId
            },
            query: {
              diagram: diagram.id,
              model: diagram.modelId
            }
          },
          type: 'type' in diagram ? diagram.type : 'group',
          viewedOrEditedAt: this.userId && this.selectedRecent === 'views' ? diagram.stats.views.all.users[this.userId]?.updatedAt : this.userId ? diagram.stats.edits.all.users[this.userId]?.updatedAt : undefined,
          views: diagram.stats.views.all
        }
      })
  }

  get recentFlows () {
    return Object
      .values(this.flowModule.flows)
      .filter(o => this.diagrams.some(diagram => diagram.id === o.diagramId) && this.actionLogs.some(log => log.action.id === o.handleId))
      .map((flow): IOverviewCarouselItem => {
        const diagram = flow.diagramId ? this.diagramModule.diagrams[flow.diagramId] : undefined
        const diagramModel = diagram && diagram.modelId ? this.modelModule.objects[diagram.modelId] : undefined
        return {
          edits: flow.stats.edits.all,
          id: flow.id,
          index: flow.index,
          name: flow.name,
          permission: this.landscapePermission || 'read',
          pinned: 'pinned' in flow ? flow.pinned : undefined,
          pinnedAt: 'pinnedAt' in flow ? flow.pinnedAt : undefined,
          thumbnailUrl: this.flowModule.flowThumbnails[flow.id]?.url,
          to: {
            name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
            params: {
              landscapeId: this.currentLandscapeId || '',
              versionId: this.currentVersionId
            },
            query: {
              diagram: diagram ? diagram.handleId : undefined,
              flow: flow.handleId,
              model: diagramModel ? diagramModel.handleId : undefined
            }
          },
          type: 'flow',
          viewedOrEditedAt: this.userId && this.selectedRecent === 'views' ? flow.stats.views.all.users[this.userId]?.updatedAt : this.userId ? flow.stats.edits.all.users[this.userId]?.updatedAt : undefined,
          views: flow.stats.views.all
        }
      })
  }

  get loading () {
    return !this.currentShareLink && (
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramGroupsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramGroupsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.modelModule.objectsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get loadingRecent () {
    return this.loading || this.historyModule.actionLogsListStatus.successInfo.landscapeId !== this.currentLandscapeId
  }

  get displayedDiagrams () {
    return [...this.pinnedItems, ...this.recentlyViewedOrEditedItems].flatMap(item => {
      if ('id' in item) {
        return [item.id]
      }
      return []
    })
  }

  @Watch('displayedDiagrams')
  async onDisplayedDiagramsChange (newDiagramIds: string[]) {
    const diagramsToFetch = newDiagramIds.filter(id => {
      const diagram = this.diagramModule.diagrams[id]
      return diagram && !this.fetchedDiagramThumbnails.has(id) && !this.diagramModule.diagramThumbnails[diagram.id]?.url
    })

    await Promise.all(diagramsToFetch.map(async diagramId => {
      try {
        this.fetchedDiagramThumbnails.add(diagramId)
        await this.diagramModule.diagramThumbnailGet({
          diagramId,
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        })
      } catch (error) {
        console.error(`Failed to fetch thumbnail for diagram ${diagramId}:`, error)
      }
    }))
  }

  @Watch('recentFlows')
  async onFlowsChange (newVal: Flow[]) {
    const flowsToFetch = newVal
      .filter(({ id }) => !this.fetchedFlowThumbnails.has(id) && !this.flowModule.flowThumbnails[id]?.url)
      .map(({ id }) => id)

    await Promise.all(flowsToFetch.map(async flowId => {
      try {
        this.fetchedFlowThumbnails.add(flowId)
        await this.flowModule.flowThumbnailGet({
          flowId,
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        })
      } catch (error) {
        console.error(`Failed to fetch thumbnail for flow ${flowId}:`, error)
      }
    }))
  }

  @Watch('selectedRecent')
  @Watch('currentLandscapeId', { immediate: true })
  onCurrentLandscapeIdChange () {
    if (this.currentLandscapeId && !this.currentShareLink) {
      this.getActionLogs()
    }
  }

  selectRecent (recent: string) {
    this.$replaceQuery({
      recent
    })

    localStorage.setItem('overviewSelectedRecentTab', recent)
  }

  async getActionLogs () {
    const { actionLogs } = await this.historyModule.actionLogsList({
      filter: {
        actionId: this.userId,
        actionType: this.selectedRecent === 'views' ? ['diagram-content-view', 'flow-view'] : ['diagram-content-update', 'flow-update'],
        includeActionsInContext: true
      },
      landscapeId: this.currentLandscapeId
    })
    if (this.selectedRecent === 'views') {
      this.viewedActionLog = actionLogs
    } else {
      this.recentActionLog = actionLogs
    }
  }
}
