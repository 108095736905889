import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Screens
 */

export const overviewScreen = new analytics.Event({
  displayName: 'Overview screen',
  name: 'overview_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
