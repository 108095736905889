
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { StatsUser } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '@/helpers/analytics'
import getColor from '@/helpers/get-color'
import { AlertModule } from '@/modules/alert/store'
import DiagramContextMenu from '@/modules/diagram/components/diagram-context-menu/index.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import FlowContextMenu from '@/modules/flow/components/flow-context-menu/index.vue'
import { FlowModule } from '@/modules/flow/store'
import { ShareModule } from '@/modules/share/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

import { CarouselType, IOverviewCarouselItem } from '../../../types'

@Component({
  components: {
    DiagramContextMenu,
    FlowContextMenu
  },
  name: 'OverviewCarouselItem'
})
export default class OverviewCarouselItem extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly item!: IOverviewCarouselItem
  @Prop() readonly itemLength!: number
  @Prop({ type: String }) readonly type?: CarouselType

  imageLoaded = false
  imageError = false

  get diagram () {
    return this.diagramModule.diagrams[this.item.id]
  }

  get flow () {
    return this.flowModule.flows[this.item.id]
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get dateValue () {
    return this.type === 'pinned' ? this.item.lastUpdated : this.item.viewedOrEditedAt
  }

  get dateLabel () {
    return this.selectedTab === 'views' ? 'Viewed' : 'Edited'
  }

  get emptyStateSubtitle () {
    return this.selectedTab === 'views' ? 'No views' : 'No edits'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get actionsEnabled () {
    return !this.currentShareLink && this.currentVersion?.tags.includes('latest')
  }

  get selectedTab () {
    return this.$queryValue('recent') || 'edits'
  }

  get displayedUsers () {
    let usersArray: StatsUser[] = []
    if (this.selectedTab === 'views') {
      usersArray = Object.values(this.item.views.users)
    } else {
      usersArray = Object.values(this.item.edits.users)
    }

    return usersArray
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .filter(o => o.name)
      .slice(0, 3)
  }

  get numberOfAdditionalUsers () {
    return Object.values(this.item.edits.users).length - 3
  }

  userColor (id?: string) {
    return id ? TAG_COLOR_ACTIVE[getColor(id)] : undefined
  }

  userInitials (name?: string) {
    return name ? getInitials(name) : undefined
  }

  handleClick (id: string) {
    if (this.item.type === 'flow') {
      this.togglePinFlow(id)
    } else {
      this.togglePinDiagram(id)
    }
  }

  togglePinDiagram (diagramId: string) {
    const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(diagramId, {
      pinned: !this.diagram.pinned,
      pinnedIndex: this.itemLength
    })
    this.diagramModule.setDiagramVersion(diagram)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpdate({
        diagramId,
        landscapeId: this.diagram.landscapeId,
        props: diagramUpdate,
        versionId: this.diagram.versionId
      })
    })

    if (this.diagram.pinned) {
      analytics.pinItem.track(this, {
        pinType: 'diagram'
      })
    } else {
      analytics.unpinItem.track(this, {
        pinType: 'diagram'
      })
    }

    this.alertModule.pushAlert({
      message: this.diagram.pinned ? 'Diagram pinned' : 'Diagram unpinned'
    })
  }

  togglePinFlow (flowId: string) {
    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(flowId, {
      pinned: !this.flow.pinned,
      pinnedIndex: this.itemLength
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId,
        landscapeId: this.flow.landscapeId,
        props: flowUpdate,
        versionId: this.flow.versionId
      })
    })

    if (this.flow.pinned) {
      analytics.pinItem.track(this, {
        pinType: 'flow'
      })
    } else {
      analytics.unpinItem.track(this, {
        pinType: 'flow'
      })
    }

    this.alertModule.pushAlert({
      message: this.flow.pinned ? 'Flow pinned' : 'Flow unpinned'
    })
  }
}
