
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { RankItem } from '../../types'
import RankListItem from './rank-list-item.vue'

@Component({
  components: {
    RankListItem
  },
  name: 'OverviewRankList'
})
export default class extends Vue {
  @Prop() readonly diagramItems!: RankItem[]
  @Prop() readonly selectedDiagramId?: string
}
