
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

export interface NavigationItem {
  icon: string
  text: string
  to: RawLocation | string
}

@Component({
  name: 'OverviewCarouselNavigationItem'
})
export default class OverviewCarouselNavigationItem extends Vue {
  @Prop() readonly navigationItem!: NavigationItem

  navigate () {
    this.$router.push(this.navigationItem.to)
  }
}
