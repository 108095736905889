
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import ShareDialog from '@/modules/share/components/dialog/index.vue'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  components: {
    ShareDialog
  },
  name: 'OverviewRankEmptyState'
})
export default class extends Vue {
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  get selectedTab () {
    return this.$queryValue('rank_tab') || this.rankTabForPermission
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion?.landscapeId)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get rankTabForPermission () {
    return this.currentLandscapePermission === 'read' ? 'views' : 'edits'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get showInvite () {
    return this.currentLandscapePermission === 'admin'
  }

  get showShareLink () {
    return this.currentLandscapePermission && this.currentLandscapePermission !== 'read' && (this.currentOrganizationLimits.shareLinkVersions || this.currentVersion?.tags.includes('latest'))
  }
}
