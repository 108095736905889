
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { count } from '@/modules/recommendation/helpers/recommendations'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import HealthCheckBox from './health-check-box.vue'

@Component({
  components: {
    HealthCheckBox
  },
  name: 'OverviewHealthChecks'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  loadingComments = false

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get comments () {
    return Object.values(this.commentModule.comments)
  }

  get activeCommentsCount () {
    return Object.values(this.commentModule.activeComments).length
  }

  get commentQuestionsCount () {
    return Object.values(this.commentModule.activeComments).filter(o => o.body.type === 'question').length
  }

  get inaccurateCommentCount () {
    return Object.values(this.commentModule.activeComments).filter(o => o.body.type === 'inaccurate').length
  }

  get currentModelObjects () {
    return Object.values(this.modelModule.objects)
  }

  get invalidObjectLinkCount () {
    return this.currentModelObjects.reduce((p, c) => p + Object.values(c.links).filter(l => 'status' in l && l.status === 'invalid').length, 0)
  }

  get inaccuracyCount () {
    return this.inaccurateCommentCount + this.invalidObjectLinkCount
  }

  get recommendationCount () {
    let totalCount = 0
    const totalCountObject = count({
      activeComments: this.commentModule.activeComments,
      diagramContents: this.diagramModule.diagramContents,
      diagrams: this.diagramModule.diagrams,
      flows: this.flowModule.flows,
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })

    totalCount += totalCountObject.recommendation_score_objects_without_name_count
    totalCount += totalCountObject.recommendation_score_objects_without_description_count

    totalCount += totalCountObject.recommendation_score_connections_without_name_count

    totalCount += totalCountObject.recommendation_score_flows_without_name_count
    totalCount += totalCountObject.recommendation_score_flow_steps_without_description_count

    totalCount += totalCountObject.recommendation_score_diagrams_without_name_count
    totalCount += totalCountObject.recommendation_score_diagrams_without_description_count

    totalCount += totalCountObject.recommendation_score_comments_question_open_count

    return totalCount
  }

  mounted () {
    if (this.currentLandscapeId) {
      this.loadComments()
    }
  }

  async loadComments () {
    try {
      this.loadingComments = true

      await this.commentModule.commentsList({
        landscapeId: this.currentLandscapeId,
        status: ['open', 'active'],
        versionId: this.currentVersionId
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingComments = false
    }
  }

  selectInnacuracyScore () {
    this.$replaceQuery({ accuracy_menu: 'reality' })
  }

  selectQuestionsBox () {
    this.$replaceQuery({ recommendations_menu: 'questions' })
  }

  selectCommentBox () {
    this.$replaceQuery({ comments_list_menu: 'all' })
  }

  selectRecommendationsBox () {
    this.$replaceQuery({ recommendations_menu: 'naming' })
  }
}
