
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { CarouselRecentsTab, CarouselType } from '@/modules/overview/types'

@Component({
  name: 'OverviewCarouselEmptyState'
})
export default class OverviewCarouselEmptyState extends Vue {
  @Prop() readonly type!: CarouselType
  @Prop() readonly selectedRecentsTab?: CarouselRecentsTab

  get image () {
    switch (this.type) {
      case 'pinned':
        return require('@/assets/brian-leaving.svg')
      case 'recent':
        return require('@/assets/brian-space.png')
    }
  }

  get imageSizeClass () {
    switch (this.type) {
      case 'pinned':
        return 'tw-h-32'
      case 'recent':
        return 'tw-w-32'
    }
  }

  get title () {
    switch (this.type) {
      case 'pinned':
        return 'Nothing pinned yet'
      case 'recent':
        return `No recently ${this.selectedRecentsTab === 'edits' ? 'edited' : 'viewed'} diagrams or flows`
    }
  }

  get subtitle () {
    switch (this.type) {
      case 'pinned':
        return 'Pin view so others know where to start'
      default:
        return ''
    }
  }
}
